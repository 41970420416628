<template>
  <layout
    :activeIndex="activeIndex"
    :activeModel.sync="activeModel"
    :topBarItems="topBarItems"
    @setIndex="setIndex"
  >
    <!--    <template #top>-->
    <!--      <b-row>-->
    <!--        <b-col v-for="(v, i) in mode" :key="i" cols="2">-->
    <!--          <b-alert show variant="secondary">-->
    <!--            <div class="alert-body d-flex align-items-center">-->
    <!--              <i :class="v.icon" class="pr-1" style="font-size: 2rem"></i><span>{{ v.title }}</span>-->
    <!--            </div>-->
    <!--          </b-alert>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </template>-->

    <template #left-0>
      <left-menu :system="system" @select="leftMenuSelect"></left-menu>
    </template>

    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input
          placeholder="输入设备名称查询"
          v-model="equipFilter.equip_name"
          trim
        ></b-form-input>

        <label class="mt-1">楼层</label>
        <v-select
          append-to-body
          clearable
          multiple
          v-model="equipFilter.floor"
          :options="equipFilterOptions.floor"
          :placeholder="$t('No filter')"
          class="w-100"
        />

        <div class="text-right mt-2">
          <b-btn class="mr-1" @click="equipFilterReset">重置</b-btn>
          <b-btn variant="primary" @click="equipFilterSubmit">查询</b-btn>
        </div>
      </b-card>
    </template>
    <!--		<template #left-2>-->
    <!--			<b-card no-body>-->
    <!--				<b-list-group flush>-->
    <!--					<b-list-group-item v-for="(v, i) in units" :key="i" class="py-1 px-2 text-center">-->
    <!--						<span> {{ v }}</span>-->
    <!--					</b-list-group-item>-->
    <!--				</b-list-group>-->
    <!--			</b-card>-->

    <!--			<b-card>-->
    <!--				<label>分组选择</label>-->
    <!--				<b-form-select v-model="selected_group" :options="options_group" value-field="text"></b-form-select>-->

    <!--				<label class="mt-1">日程选择</label>-->
    <!--				<b-form-select v-model="selected_calendar" :options="options_calendar" value-field="text"></b-form-select>-->

    <!--				<div class="pt-2 float-right">-->
    <!--					<b-button variant="primary">一键设定</b-button>-->
    <!--				</div>-->
    <!--			</b-card>-->
    <!--		</template>-->

    <!--		<template #right-0>-->
    <!--			<b-card>-->
    <!--				<b-card-text class="d-flex justify-content-between align-items-center">-->
    <!--					<b-form-checkbox v-model="flag" checked="true" name="check-button" switch>-->
    <!--						<span class="switch-icon-left">自动</span>-->
    <!--						<span class="switch-icon-right"> 手动 </span>-->
    <!--					</b-form-checkbox>-->

    <!--					<div class="d-flex">-->
    <!--						<div class="ml-2">-->
    <!--							室外温度-->
    <!--							<span class="text-primary font-weight-bold span-text">2</span>℃-->
    <!--						</div>-->
    <!--						<div class="ml-2">-->
    <!--							室外湿度-->
    <!--							<span class="text-primary font-weight-bold span-text">32</span>%-->
    <!--						</div>-->
    <!--						<div class="ml-2">-->
    <!--							室外焓值-->
    <!--							<span class="text-primary font-weight-bold span-text">85</span>kJ/kg-->
    <!--						</div>-->
    <!--					</div>-->
    <!--				</b-card-text>-->
    <!--			</b-card>-->
    <!--		</template>-->

    <template #right-1>
      <b-card no-body>
        <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          striped
          show-empty
        >
          <template v-slot:empty="scope">
            <div class="my-5">
              <h4 class="text-center">{{ $t("No Data") }}</h4>
            </div>
          </template>
          <template v-slot:cell(params)="data">
            <template v-for="(param, param_index) in data.item.params">
              <span
                v-if="param.param_name"
                :style="param.cur_value === null ? 'opacity:0.3' : ''"
              >
                <span class="mx-50" v-if="param_index !== 0">,</span>
                <span>{{
                  param.param_name.replace(data.item.equip_name + "-", "")
                }}</span
                >:
                <span v-if="param.cur_value === null">N/A</span>
                <span v-else>{{
                  param.unit && param.unit.indexOf("||") > -1
                    ? param.unit.split("||")[param.cur_value > 0 ? 1 : 0]
                    : param.cur_value + " " + (param.unit || "")
                }}</span>
              </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm"
                >查看
              </b-button>
              <b-button
                variant="success"
                size="sm"
                @click="equip_to_detail_model(data.item)"
                :disabled="!data.item.detail_model_key"
                >详情
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        align="right"
        first-number
        hide-goto-end-buttons
        last-number
        next-class="next-item"
        prev-class="prev-item"
      />
    </template>
    <!--		<template #right-2>-->
    <!--			<b-row>-->
    <!--				<b-col class="d-flex justify-content-end" cols="12" style="margin-bottom: 2rem">-->
    <!--					<b-button-group>-->
    <!--						<b-button-->
    <!--							v-for="(v, i) in action"-->
    <!--							:key="i"-->
    <!--							@click="-->
    <!--								action_index = i;-->
    <!--								$bvModal.show('modal-scoped');-->
    <!--							"-->
    <!--							>{{ v.text }}-->
    <!--						</b-button>-->
    <!--					</b-button-group>-->
    <!--				</b-col>-->
    <!--			</b-row>-->

    <!--			<b-row>-->
    <!--				<b-col cols="12">-->
    <!--					<b-card no-body>-->
    <!--						<b-table :current-page="mode_currentPage" :fields="mode_fields" :items="mode_items" :per-page="mode_perPage" hover responsive striped>-->
    <!--							<template v-slot:cell(index)="data">-->
    <!--								{{ data.index + 1 }}-->
    <!--							</template>-->
    <!--							<template v-slot:cell(cs)="data">-->
    <!--								<b-form-select v-model="mode_select" :options="data.item.cs" size="sm"></b-form-select>-->
    <!--							</template>-->
    <!--							<template v-slot:cell(action)="data">-->
    <!--								<b-button class="mr-1" size="sm" variant="primary">应用</b-button>-->
    <!--								<b-button size="sm" variant="outline-primary">解绑</b-button>-->
    <!--							</template>-->
    <!--						</b-table>-->
    <!--					</b-card>-->
    <!--					<b-pagination v-model="currentPage" :total-rows="rows" align="right" first-number hide-goto-end-buttons last-number next-class="next-item" prev-class="prev-item" />-->
    <!--				</b-col>-->
    <!--			</b-row>-->
    <!--		</template>-->
    <template #right-2>
      <panel-group :show-title="false" group-uid="3988f97d"></panel-group>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { computed, reactive, toRefs, ref, watch } from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "cold-source",
  components: {
    Layout,
    LeftMenu,
    vSelect,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "CAHS/CAHS",
            isDetail: false,
            dbIds: ["equipment_"],
            rotate: "37093,-12693,14432",
          },
        },
        { title: "设备列表" },
        /* { title: "模式管理" }, */
        { title: "数据分析" },
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      // activeModel.value = event.model
      if (event?.equip_name) {
        event.model.isDetail = true;
        layoutParams.activeModel = event.model;
      } else {
        event.model.isDetail = false;
        layoutParams.activeModel = event.model;
      }
    };
    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };
    const data = reactive({
      flag: false,
      selected_group: "全部设备",
      options_group: [
        {
          text: "全部设备",
        },
        {
          text: "公共区域",
        },
        {
          text: "办公区域",
        },
        {
          text: "大堂区域",
        },
      ],
      selected_calendar: "冬季工作日",
      options_calendar: [
        {
          text: "冬季工作日",
        },
        {
          text: "冬季节假日",
        },
        {
          text: "夏季工作日",
        },
        {
          text: "夏季节假日",
        },
        {
          text: "过渡季工作日",
        },
        {
          text: "过渡季节假日",
        },
      ],
      status: [
        {
          text: "主页",
          active: true,
        },
        {
          text: "设备列表",
          active: false,
        },
        {
          text: "模式管理",
          active: false,
        },
      ],
      nodes: [
        {
          name: "制冷机",
          open: true,
          children: [
            {
              name: "制冷机-001",
            },
            {
              name: "制冷机-002",
            },
            {
              name: "制冷机-003",
            },
            {
              name: "制冷机-004",
            },
            {
              name: "制冷机-005",
            },
          ],
        },
        {
          name: "冷冻泵",
          open: true,
          children: [
            {
              name: "冷冻泵-001",
            },
            {
              name: "冷冻泵-002",
            },
            {
              name: "冷冻泵-003",
            },
            {
              name: "冷冻泵-004",
            },
            {
              name: "冷冻泵-005",
            },
          ],
        },
        {
          name: "冷却泵",
          open: true,
          children: [
            {
              name: "冷却泵-001",
            },
            {
              name: "冷却泵-002",
            },
            {
              name: "冷却泵-003",
            },
            {
              name: "冷却泵-004",
            },
          ],
        },
        {
          name: "冷却塔",
          open: true,
          children: [
            {
              name: "冷却塔-001",
            },
            {
              name: "冷却塔-002",
            },
            {
              name: "冷却塔-003",
            },
            {
              name: "冷却塔-004",
            },
          ],
        },
        {
          name: "板换",
          open: true,
          children: [
            {
              name: "板换-001",
            },
            {
              name: "板换-002",
            },
            {
              name: "板换-003",
            },
            {
              name: "板换-004",
            },
          ],
        },
      ],
      units: ["制冷机", "冷冻泵", "冷切泵", "冷却塔", "板换"],
      mode: [
        {
          icon: "iconfont icon-sound-module-line",
          title: "冬季工作日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "冬季节假日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "夏季工作日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "夏季节假日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "过渡季工作日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "过渡季节假日模式",
        },
        /* {
                  icon: "iconfont icon-sound-module-line",
                  title: "手动运行模式",
              }, */
      ],
      //设备列表
      fields: [
        { label: "ID", key: "equip_id" },
        { label: "设备名称", key: "equip_name" },
        { label: "设备编号", key: "equip_meter_code" },
        { label: "楼层", key: "floor" },
        { label: "参数状态", key: "params" },
        { label: "操作", key: "action" },
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      select: 0,

      action: [
        {
          text: "新建日程",
        },
        {
          text: "编辑日程",
        },
      ],
      action_index: 0,

      // 模式管理
      mode_fields: [
        { key: "index", label: "ID" },
        { key: "plant_no", label: "设备编号" },
        { key: "plant_name", label: "设备名称" },
        { key: "area", label: "区域" },
        { key: "floor", label: "楼层" },
        { key: "group", label: "分组名称" },
        { key: "ced", label: "当前生效日程" },
        { key: "cd", label: "日程说明" },
        { key: "cs", label: "日程选择" },
        { key: "action", label: "操作" },
      ],
      mode_items: [
        {
          plant_no: "jsf35_chiller_001",
          plant_name: "制冷机-001",
          area: "南楼",
          floor: "一层",
          group: "公共区域",
          ced: "冬季工作日",
          cd: "启 8:30 / 停 6:30",
          cs: [
            { value: 0, text: "冬季工作日" },
            { value: 1, text: "冬季节假日" },
            {
              value: 2,
              text: "夏季工作日",
            },
            { value: 3, text: "夏季节假日" },
            { value: 4, text: "过渡季工作日" },
            { value: 5, text: "过渡季节假日" },
          ],
        },
        {
          plant_no: "jsf35_chiller_001",
          plant_name: "制冷机-001",
          area: "南楼",
          floor: "一层",
          group: "公共区域",
          ced: "冬季工作日",
          cd: "启 8:30 / 停 6:30",
          cs: [
            { value: 0, text: "冬季工作日" },
            { value: 1, text: "冬季节假日" },
            {
              value: 2,
              text: "夏季工作日",
            },
            { value: 3, text: "夏季节假日" },
            { value: 4, text: "过渡季工作日" },
            { value: 5, text: "过渡季节假日" },
          ],
        },
        {
          plant_no: "jsf35_chiller_001",
          plant_name: "制冷机-001",
          area: "南楼",
          floor: "一层",
          group: "公共区域",
          ced: "冬季工作日",
          cd: "启 8:30 / 停 6:30",
          cs: [
            { value: 0, text: "冬季工作日" },
            { value: 1, text: "冬季节假日" },
            {
              value: 2,
              text: "夏季工作日",
            },
            { value: 3, text: "夏季节假日" },
            { value: 4, text: "过渡季工作日" },
            { value: 5, text: "过渡季节假日" },
          ],
        },
        {
          plant_no: "jsf35_chiller_001",
          plant_name: "制冷机-001",
          area: "南楼",
          floor: "一层",
          group: "公共区域",
          ced: "冬季工作日",
          cd: "启 8:30 / 停 6:30",
          cs: [
            { value: 0, text: "冬季工作日" },
            { value: 1, text: "冬季节假日" },
            {
              value: 2,
              text: "夏季工作日",
            },
            { value: 3, text: "夏季节假日" },
            { value: 4, text: "过渡季工作日" },
            { value: 5, text: "过渡季节假日" },
          ],
        },
        {
          plant_no: "jsf35_chiller_001",
          plant_name: "制冷机-001",
          area: "南楼",
          floor: "一层",
          group: "公共区域",
          ced: "冬季工作日",
          cd: "启 8:30 / 停 6:30",
          cs: [
            { value: 0, text: "冬季工作日" },
            { value: 1, text: "冬季节假日" },
            {
              value: 2,
              text: "夏季工作日",
            },
            { value: 3, text: "夏季节假日" },
            { value: 4, text: "过渡季工作日" },
            { value: 5, text: "过渡季节假日" },
          ],
        },
      ],
      mode_select: 0,
      mode_perPage: 16,
      mode_currentPage: 1,
      mode_rows: 1,
    });
    const changeStatus = ({ v, i }) => {
      data.nodes[i].open = !v;
    };
    watch(
      () => store.state.jsf35.equip_list,
      (list) => {
        data.items = list;
        data.rows = list.length;
      }
    );

    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0);
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item);
      }, 1000);
    };
    /**
     * 设备列表查询
     * @type {Ref<UnwrapRef<{equip_name: string, floor: string}>>}
     */
    const equipFilter = ref({
      equip_name: "",
      floor: "",
    });
    const equipFilterOptions = ref({
      floor: [
        "RF",
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
    });

    const equipFilterSubmit = () => {
      const { equip_name, floor } = equipFilter.value;
      data.items = store.state.jsf35.equip_list.filter((item) => {
        return (
          (equip_name
            ? item.equip_name.toLowerCase().includes(equip_name.toLowerCase())
            : true) && (floor ? floor.includes(item.floor) : true)
        );
      });
      data.rows = data.items.length;
    };

    const equipFilterReset = () => {
      console.log("equipFilterReset");
      equipFilter.value = {
        equip_name: "",
        floor: "",
      };
      equipFilterSubmit();
    };
    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      changeStatus,
      equip_to_detail_model,
      equipFilter,
      equipFilterOptions,
      equipFilterSubmit,
      equipFilterReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.info_list {
  .info_top {
    .status {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.6rem;
    }

    padding-bottom: 0.4rem;
    border-bottom: 1px solid #cccccc;
  }

  .info_center {
    .weather {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.6rem;
    }

    padding-top: 1rem;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid #cccccc;
  }

  .info_bottom {
    padding-top: 1rem;

    .value {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.6rem;

      .vl {
        flex: 5;
      }

      .vr {
        flex: 4;
        display: flex;

        .vrl {
          flex: 6;
        }

        .vrr {
          flex: 4;
        }
      }
    }
  }
}

.bold-text {
  font-weight: bolder;
  font-size: 1.5rem;
}

.span-text {
  font-size: 1.6rem;
  line-height: 1.5rem;
}

div ::v-deep .custom-control-label {
  margin-bottom: 0 !important;
}
</style>
